import {
  splitCloudinaryCropImageUrl,
  useUploadWidget,
} from '../hooks/useUploadWidget';

import {Button} from '~/modules/Common/components/Button';
import {FC} from 'react';
import {UploadIcon} from '@heroicons/react/outline';

export const ImageForm: FC<{
  name: string;
  width?: number;
  height?: number;
  onChange: (value: string) => void;
  onRemoveImageClick: () => void;
  uploadedFileUrl?: string;
  className?: string;
  enforceAspect?: boolean;
  id?: string;
}> = ({
  id,
  name,
  width = 100,
  onChange,
  uploadedFileUrl,
  className = '',
  height = 100,
  onRemoveImageClick,
  enforceAspect = true,
}) => {
  const uploadWidget = useUploadWidget({
    options: {
      resourceType: 'image',
      cropping: true,
      croppingValidateDimensions: true,
      minImageWidth: width,
      minImageHeight: height,
      croppingShowDimensions: true,
      ...(enforceAspect
        ? {croppingAspectRatio: Math.round((width / height) * 100) / 100}
        : {}),
      multiple: false,
      croppingCoordinatesMode: 'custom',
      showPoweredBy: false,
    },
    onUpload: (url) => {
      const cropped = splitCloudinaryCropImageUrl(url);
      onChange(cropped);
    },
  });

  return (
    <div className={`flex flex-col gap-2 ${className}`} {...(id ? {id} : {})}>
      <Button
        color="light"
        type="button"
        className="border-codGray font-internal"
        onClick={uploadWidget}
      >
        <div className="flex flex-row gap-1 items-center ">
          <span className="text-xs text-center font-internal">
            Upload{uploadedFileUrl ? ' New' : ''} Image
          </span>
        </div>
      </Button>
      {uploadedFileUrl && (
        <button
          className="underline text-sm text-left font-internal"
          onClick={onRemoveImageClick}
        >
          Remove image
        </button>
      )}
      <input type="hidden" value={uploadedFileUrl} name={name} />
    </div>
  );
};
